<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card">
        <DataTable
          :value="emps"
          :paginator="true"
          class="p-datatable-customers p-datatable-striped"
          :rows="10"
          dataKey="id"
          :rowHover="true"
          :autoLayout="true"
          :loading="isLoading"
          v-model:filters="filters"
          v-model:selection="selectedUser"
          selectionMode="single"
          filterDisplay="row"
          stateStorage="session"
          stateKey="users-table-state"
          :rowClass="rowClass"
          @row-click="goToUserPage"
        >
          <template #header>
            <div class="table-header p-mb-3">
              Сотрудники
              <span class="p-input-icon-left" />
              <div class="buttons">
                <Button @click="downloadList">
                  Скачать список
                </Button>
                <router-link style="margin-left: 8px" :to="{ name: 'UserCreate' }">
                  <Button>Новый сотрудник</Button>
                </router-link>
              </div>
            </div>

            <InputText
              v-model="filters.lastname.value"
              placeholder="Введите фамилию"
              class="lastname-filter"
            />
          </template>
          <template #empty> Пользователи не найдены.</template>
          <template #loading> Загрузка данных. Подождите, пожалуйста.</template>
          <Column
            field="lastname"
            header="Фамилия"
            :sortable="true"
            :showFilterMenu="false"
          >
            <template #body="slotProps">
              <router-link
                :to="{
                  name: 'UserDetail',
                  params: { id: slotProps.data.id },
                }"
                >{{ slotProps.data.lastname }}</router-link
              >
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                @keydown.enter="filterCallback()"
                class="p-column-filter"
                :placeholder="``"
                v-tooltip.top.focus="''"
              />
            </template>
          </Column>
          <Column
            field="firstname"
            header="Имя"
            :sortable="true"
            :showFilterMenu="false"
          >
            <template #body="slotProps">
              {{ slotProps.data.firstname }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                @keydown.enter="filterCallback()"
                class="p-column-filter"
                :placeholder="``"
                v-tooltip.top.focus="''"
              />
            </template>
          </Column>
          <Column
            field="email"
            header="email"
            :sortable="true"
            :showFilterMenu="false"
          >
            <template #body="slotProps">
              <a
                v-if="slotProps.data.email"
                :href="'mailto:' + slotProps.data.email"
                >{{ slotProps.data.email }}</a
              >
              <p v-else>-</p>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                @keydown.enter="filterCallback()"
                class="p-column-filter"
                :placeholder="``"
                v-tooltip.top.focus="''"
              />
            </template>
          </Column>
          <Column
            field="phone"
            header="Телефон"
            :sortable="true"
            :showFilterMenu="false"
          >
            <template #body="slotProps">
              <a :href="'tel:' + slotProps.data.phone">{{
                slotProps.data.phone
              }}</a>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                @keydown.enter="filterCallback()"
                class="p-column-filter"
                :placeholder="``"
                v-tooltip.top.focus="''"
              />
            </template>
          </Column>
          <Column
            field="role"
            header="Роль"
            :sortable="true"
            :showFilterMenu="false"
          >
            <template #body="slotProps">
              {{ slotProps.data.roleText }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <MultiSelect
                id="role"
                v-model="filterModel.value"
                :options="allRoles"
                optionLabel="name"
                optionValue="value"
                @change="filterCallback()"
                class="p-column-filter"
                :placeholder="``"
                v-tooltip.top.focus="''"
                style="max-width: 250px"
              />
            </template>
          </Column>
          <Column
            field="taskCount"
            header="Количество заявок"
            :sortable="true"
            :showFilterMenu="false"
          >
            <template #body="slotProps">
              <p v-if="slotProps.data.taskCount !== 0">
                {{ slotProps.data.taskCount }}
              </p>
              <p v-else>-</p>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                @keydown.enter="filterCallback()"
                class="p-column-filter"
                :placeholder="``"
                v-tooltip.top.focus="''"
              />
            </template>
          </Column>
          <Column
              field="region"
              header="Филиалы"
              :sortable="true"
              :showFilterMenu="false"
          >
            <template #body="slotProps">
              {{ slotProps.data.region.join(', ') }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <MultiSelect
                  id="region"
                  v-model="filterModel.value"
                  :options="REGIONS"
                  optionLabel="name"
                  optionValue="name"
                  @change="filterCallback()"
                  class="p-column-filter"
                  :placeholder="``"
                  style="max-width: 250px"
              />
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <!--              Нестандартная ситуация-->
              <Button
                v-if="
                  !slotProps.data.block &&
                  (role === 'ADMIN' ||
                    (role === 'OPERATOR' && slotProps.data.role !== 'ADMIN'))
                "
                icon="pi pi-lock"
                class="p-button-rounded p-button-success p-mr-2"
                v-tooltip="'Заблокировать пользователя'"
                @click="blockUser(slotProps.data, 'block')"
              />
              <Button
                v-if="
                  slotProps.data.block &&
                  (role === 'ADMIN' ||
                    (role === 'OPERATOR' && slotProps.data.role !== 'ADMIN'))
                "
                icon="pi pi-unlock"
                class="p-button-rounded p-button-warning"
                v-tooltip="'Разблокировать пользователя'"
                @click="blockUser(slotProps.data, 'unblock')"
              />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { environment } from "@/config";
import { FilterMatchMode, FilterService } from "primevue/api";
import { ROLES_LIST_SELECT } from "@/models/roles";
import { REGIONS } from "@/models/regionsList";

export default {
  name: "UsersList",
  data() {
    return {
      allRoles: ROLES_LIST_SELECT,
      REGIONS,
      filteredEngineers: [],
      isLoading: false,
      selectedUser: null,
      filters: {
        id: { value: null, matchMode: FilterMatchMode.CONTAINS },
        lastname: { value: null, matchMode: FilterMatchMode.CONTAINS },
        firstname: { value: null, matchMode: FilterMatchMode.CONTAINS },
        email: { value: null, matchMode: FilterMatchMode.CONTAINS },
        phone: { value: null, matchMode: FilterMatchMode.CONTAINS },
        role: { value: null, matchMode: FilterMatchMode.IN },
        taskCount: { value: null, matchMode: FilterMatchMode.CONTAINS },
        region: { value: null, matchMode: 'regionFilter' }
      },
    };
  },
  created() {
    this.loadEmployes();

    FilterService.register('regionFilter', (value, filter) => {
      if (filter === undefined || filter === null) {
        return true;
      }

      if (value === undefined || value === null) {
        return false;
      }

      let arr = Object.values(filter)

      if (arr.length === 0)
        return true

      return !!filter.some(v => value.includes(v));
    });
  },

  methods: {
    goToUserPage(event) {
      this.$router.push(`/user/${event.data.id}`);
    },

    rowClass(data) {
      return data.block ? "blocked-user" : null;
    },

    async loadEmployes() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("myprofiles/loadEmployes");
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },
    async blockUser(data, state) {
      this.isLoading = true;

      try {
        const response = await fetch(
          `${environment.apiUrl}/users/${data.id}/${state}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status !== 200) {
          const responseData = await response.json();
          throw new Error(
            responseData.message || "Ошибка блокирования пользователя!"
          );
        }

        await this.loadEmployes();

        this.$toast.add({
          severity: "success",
          summary: "",
          detail: `Пользователь ${
            state === "block" ? "заблокирован" : "разблокирован"
          }!`,
          life: 6000,
        });
      } catch (err) {
        const error = err.message || "Не получилось заблокировать пользователя";
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при блокировании пользователя!",
          detail: error,
          life: 6000,
        });
      }

      this.isLoading = false;
    },

    async downloadList() {
      let filteredEngineers = this.emps.filter((e) => {
        return FilterService.filters['regionFilter'](e.region, this.$data.filters.region.value)
            && FilterService.filters.contains(e.role, this.filters.role.value);
      }).map((e) => e.id);

      try {
        const response = await fetch(
            `${environment.apiUrl}/reports/employees/excel`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${this.$store.getters.token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify(filteredEngineers),
            }
        );
        const url = window.URL.createObjectURL(await response.blob());
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Список сотрудников.xls`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (err) {
        console.log(err);
      }
    }
  },
  computed: {
    emps() {
      // Убираем сотрудников клиента из общего списка
      return this.$store.getters["myprofiles/employes"].filter(
          (e) => e.role !== "EMPLOYEE_OF_CLIENT"
      ).map((e) => {
        return {
          ...e,
          region: e.dep ? [e.dep] : e.regions.map((e) => e.name)
        }
      });
    },
    role() {
      return this.$store.getters.getRole;
    },
  },
};
</script>

<style scoped lang="scss">
.lastname-filter {
  width: 100%;

  @media (min-width: 961px) {
    display: none;
  }
}
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

::v-deep(.blocked-user) {
  background-color: rgba(255, 0, 0, 0.15) !important;
}
</style>
